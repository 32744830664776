@import"./variables.scss";

.scroll-reminder {
	color: var(--readable-secondary-color);
	position: absolute;
    display: flex;
    align-items: center;
    left: calc(50% - 70px);
	bottom: 120px;
    opacity: 1;
    transform: rotate(-90deg) translate3d(0, 0, 0);
	transition: transform 0.6s, opacity 0.6s;
	transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
	will-change: transform, opacity;
	&.hidden {
		opacity: 0;
		transform: translateY(100px) rotate(-90deg) translate3d(0, 0, 0);
	}
	.text {
		margin: 0 0 4px 6px;
		font-size: 14px;
		font-weight: 600;
	}
	.arrow {
		fill: var(--readable-secondary-color);
		animation: arrowBounce 2s infinite;
		animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
	}
	@media (max-width: $tablet-medium-width) {
		left: calc(50% - 50px);
		bottom: calc(20vh + 220px);
	}
}

@keyframes arrowBounce {
	0% {
		transform: translateX(0px)
	}
	50% {
		transform: translateX(-10px)
	}
	100% {
		transform: translateX(0px)
	}
}