@import "./variables.scss";

* {
	box-sizing: border-box;
}

.app {
	font-family: Open Sans, sans-serif;
	position: relative;
	display: flex;
	flex-direction: column;
	background: #f8f8f8;
	color: #1c1b20;
	background: var(--primary-color);
	overflow: hidden;
	&.scroll-locked {
		max-height: 100vh;
	}
}

.loading-screen {
	pointer-events: none;
	z-index: 100;
	position: fixed;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.white-border {
	z-index: 100;
	position: fixed;
	background: var(--readable-secondary-color);
	transform: translate3d(0px, 0px, 0px);
	&.left {
		top: 0;
		left: calc(var(--border-width) - calc(var(--border-width) * 2));
		width: var(--border-width);
		height: 100vh;
		transition: transform 0.4s, width 0.4s;
		&.visible {
			transform: translate3d(var(--border-width), 0px, 0px);
		}
	}
	&.right {
		top: 0;
		right: calc(var(--border-width) - calc(var(--border-width) * 2));
		width: var(--border-width);
		height: 100vh;
		transition: transform 0.4s, width 0.4s;
		&.visible {
			transform: translate3d(calc(var(--border-width) - calc(var(--border-width) * 2)), 0px, 0px);
		}
	}
	&.top {
		top: calc(var(--border-width) - calc(var(--border-width) * 2));
		left: 0;
		width: 100vw;
		height: var(--border-width);
		transition: transform 0.4s, height 0.4s;
		&.visible {
			transform: translate3d(0px, var(--border-width), 0px);
		}
	}
	&.bottom {
		bottom: calc(var(--border-width) - calc(var(--border-width) * 2));
		left: 0;
		width: 100vw;
		height: var(--border-width);
		transition: transform 0.4s, height 0.4s;
		&.visible {
			transform: translate3d(0px, calc(var(--border-width) - calc(var(--border-width) * 2)), 0px);
		}
	}
}