@import "../variables.scss";

.about-me-section {
	padding: calc(var(--border-width) + 80px);
	padding-right: var(--border-width);
	position: relative;
	background: var(--secondary-color);
	z-index: 20;
	display: flex;
	flex-direction: column;
	@media (max-width: $desktop-small-width) {
		padding: var(--border-width);
	}
	.title-section {
		padding-left: 15%;
		margin-bottom: 40px;
		@media (max-width: $desktop-small-width) {
			padding-left: 15%;
		}
		@media (max-width: $tablet-medium-width) {
			margin-top: 40px;
		}
		.zigzag-wrapper {
			width: 0px;
			height: 20px;
			overflow: hidden;
			transition: 1s;
			transition-timing-function: ease-out;
			margin-bottom: -10px;
			&.visible {
				transition-delay: 0.2s;
				width: 70px;
			}
			.zigzag {
				width: 70px;
				stroke: var(--primary-color);
			}
		}
		.text-wrapper {
			width: 100%;
			margin-bottom: -40px;
			overflow: hidden;
			font-weight: 900;
			color: var(--primary-color);
			.text {
				font-size: 92px;
				@media (max-width: $tablet-large-width) {
					font-size: 62px;
					margin-bottom: 10px;
				}
				margin: 0;
				text-transform: uppercase;
				transition: 0.5s;
				transition-timing-function: ease-out;
				transform: translateY(100%);
				&.visible {
					transform: translateY(0%);
				}
			}
		}
	}
	.content-section {
		display: flex;
		align-items: flex-start;
		@media (max-width: $desktop-small-width) {
			align-items: unset;
			flex-direction: column;
		}
		.text-section {
			flex-basis: 50%;
			padding-left: 15%;
			padding-right: 5%;
			@media (max-width: $desktop-small-width) {
				padding-right: 15%;
				padding-bottom: 40px;
			}
			.text {
				font-size: 16px;
				line-height: 32px;
				color: var(--readable-faded-primary-color);
				b {
					color: var(--readable-primary-color);
				}
			}
		}
		.skills-section-main-wrapper {
			flex-basis: 50%;
			display: flex;
			.skills-section-offset-wrapper {
				width: 100%;
				position: relative;
				display: flex;
				color: var(--readable-secondary-color);
				background: var(--readable-primary-color);
				padding: 0 100px 0 0;
				transition: padding 0.5s;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					width: 100%;
					height: 100%;
					background: var(--secondary-color);
					transition: 0.5s;
				}
				@media (max-width: $desktop-small-width) {
					position: unset;
					padding-left: 15% !important;
					padding-right: 15% !important;
				}
				@media (max-width: $tablet-small-width) {
					flex-direction: column;
				}
				@media (max-width: $tablet-small-width) {
					padding-bottom: 40px !important;
				}
				&.visible {
					padding: 0 50px 0 50px;
					&::after {
						width: 0%;
					}
				}
				.section-wrapper {
					display: flex;
					flex-direction: column;
					margin-bottom: 50px;
					@media (max-width: $tablet-small-width) {
						margin-bottom: 0;
					}
					&.developer {
						flex-basis: 66%;
					}
					&.designer {
						flex-basis: 33%;
					}
					.title-wrapper {
						.zigzag-wrapper {
							margin: 40px 0 0 0;
							width: 30px;
							height: 20px;
							overflow: hidden;
							transition: 1s;
							transition-timing-function: ease-out;
							transition-delay: 1s;
							margin-bottom: -20px;
							&.visible {
								transition-delay: 0.2s;
								width: 30px;
							}
							.zigzag {
								width: 30px;
								stroke: var(--border-color);
							}
						}
						.title {
							text-transform: uppercase;
						}
					}
					.skills-wrapper {
						display: flex;
						&.developer {
							flex-wrap: wrap;
							p {
								flex-basis: 50%;
							}
							&.desktop {
								@media (max-width: $tablet-small-width) {
									display: none;
								}
							}
							&.small-tablet {
								display: none;
								@media (max-width: $tablet-small-width) {
									display: flex;
									p {
										flex-basis: 100%;
									}
								}
							}
						}
						&.designer {
							flex-direction: column;
						}
						p {
							opacity: 0.5;
							line-height: 32px;
							margin: 0;
						}
					}
				}
			}
		}
	}
}
